import React, { Component } from "react";
import styled from "styled-components";

import data from "data/services.json";

import Circle from "components/services/circle";
import CurvedLine from 'components/line/curved';

import { sizes, colors } from "theme";

import { TimelineMax, Power0, TweenMax } from "gsap/all";
import { Container } from "semantic-ui-react";
const StyledServices = styled.section`
    background: #eeeeee;
    height: 800px;
    padding: 40px;
`;

const StyledTitle = styled.h2`
`;


const StyledMainCircleWrapper = styled.div`
    display:flex;
    justify-content: center;
`;

const StyledCirclesWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 140px;
    @media (max-width: ${sizes.xs}) {
         display: none;
     }
`;

const StyledListMenu = styled.ul`
    display: none;
    list-style: none;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 320px;
    li{
        width: 100%;
        color: ${colors.blue["50"]};
        margin-bottom: 5px;
        padding: 5px 10px;
        border:1px solid ${colors.blue["50"]};

        :hover{
            background: ${colors.blue["50"]};
            color: #ffffff;
        }
    }
    @media (max-width: ${sizes.xs}) {
        display: block;
     }
`

const StyledMainCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    width: 300px;
    height: 300px;
    background: #2881B2;
    border-radius:100%;
    margin-top: 50px;
    z-index: 1;

    @media (max-width: ${sizes.xs}) {
        margin-top: 0px;
    }
`;

const StyledCircleOutline = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position:absolute;
    width: 400px;
    height: 400px;
    border: double 2px transparent;
    background-image: linear-gradient(#eeeeee, #eeeeee), linear-gradient(transparent 30%, #2881B2);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 100%;

    @media (max-width: ${sizes.xs}) {
         display: none;
     }
`;

const StyledCircleDot = styled.div`
    display: block;
    width: 30px;
    height: 30px;
    background: #eeeeee;
    border-radius: 100%;
    position: absolute;
    border: 2px solid #2881B2;
    margin-left: -15px;
    @media (max-width: ${sizes.xs}) {
         display: none;
     }
`;

const StyledText = styled.div`
    text-align: center;
   
    color: #eeeeee;
    .title{
        font-size: 24px;
        font-weight: lighter;
        margin-bottom: 20px;
    }
    .body{
        padding: 0 30px;
    }
`;

class Services extends Component {

    constructor(props) {
        super(props);
        this.tl = new TimelineMax();
        this.dot = null;
        this.choose = this.choose.bind(this);
        this.animate = this.animate.bind(this);
    }

    static defaultProps = {
    };

    static states = {
        main: data.circles[5]
    }

    componentDidMount() {
        this.setState(
            { main: data.circles[0] }
        );
        TweenMax.from(this.dot, .5, { alpha: 0, delay: 2 });
        TweenMax.delayedCall(2, this.animate, [0, false]);
        this.animate(5, true);
    }

    choose = (id) => {
        this.setState({ main: data.circles[id] });
        this.animate(id, false);
    }

    animate(id, init) {
        const radius = 200;
        const time = 2;
        const points = [
            { x: 0, y: 0 },
            { x: radius, y: radius },
            { x: radius * 2, y: 0 },
            { x: radius, y: -radius },
            { x: 0, y: 0 }
        ];

        this.tl
            .to(this.dot, time, {
                bezier: {
                    values: points,
                    curviness: 1.5
                },
                ease: Power0.easeNone,
                transformOrigin: "center center"
            });
        this.tl
            .tweenTo(id * (time / (2 * (data.circles.length - 1))));

        if (init) {
            this.tl.tweenTo(1).duration(0);
        }
    }


    render() {
        return (
            <React.Fragment>
                <StyledServices id="services">
                    <Container>
                        <StyledTitle>
                            Our <span className="blue">Services</span>
                        </StyledTitle>
                        <StyledMainCircleWrapper>
                            <StyledCircleOutline>
                                <StyledCircleDot ref={div => this.dot = div} />
                            </StyledCircleOutline>
                        </StyledMainCircleWrapper>
                        <StyledMainCircleWrapper>
                            <StyledMainCircle >
                                <StyledText>
                                    <div className="title" dangerouslySetInnerHTML={{ __html: this.state && this.state.main.name }}>
                                    </div>
                                    <div className="body">
                                        {this.state && this.state.main.content}
                                    </div>
                                </StyledText>
                            </StyledMainCircle>
                        </StyledMainCircleWrapper>
                        <StyledCirclesWrapper>
                            {
                                data.circles.map((circle, i) => {
                                    return <Circle data={circle} id={i} key={i} length={data.circles.length} clickEvent={this.choose} />
                                })
                            }
                        </StyledCirclesWrapper>

                        <StyledListMenu>
                            {
                                data.circles.map((el, i) => {
                                    return <li id={i} key={i} onClick={() => this.choose(i)}>{el.name.replace("<br />", " ")}</li>
                                })
                            }

                        </StyledListMenu>
                    </Container>
                </StyledServices>
                <CurvedLine color="#eeeeee" type="grey" />
            </React.Fragment >
        );
    }
}

export default Services;
