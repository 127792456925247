import React, { Component } from "react";
import styled from "styled-components";

const StyledText = styled.div`
    .title{
        .first{
            font-family: "Ruthie", cursive;
            font-size: 40px;
        }
    }
    .body{
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
`;

const StyledThumb = styled.div`
    width: 300px;
    height: 200px;
    position: relative;
    background: #eeeeee;

    background: url(${props => require("assets/images/portfolio/" + props.image)}) no-repeat center center;
    background-size: cover;
 
    display: flex;
    justify-content: center;
    padding-top: 80px;
    z-index:0;

    .hover{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index:-2;
        transition: opacity .3s;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    svg line {
        stroke-width: 1;
        stroke: #2881B2;
        fill: none;
        stroke-dasharray: 200;
        transition-delay: 0s;
        transition: transform .6s;
    }

    :hover {
        .hover{
            background: rgba(255, 255, 255, 0.9);
        }
        cursor: pointer;

        div {
            opacity: 1;
        }
    }

    div {
        opacity: 0;
        transition: opacity .5s;
    }

    svg line {
        transition: all .5s;
    }

    :hover svg line {
        stroke-width: 6;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }
    
    svg line.top,
    svg line.bottom {
        stroke-dasharray: 330 240; 
    }

    svg line.left,
    svg line.right {
        stroke-dasharray: 230 140;
    }

    :hover svg line.top {
        transform: translateX(-300px);
    }

    :hover svg line.bottom {
        transform: translateX(300px);
    }

    :hover svg line.left {
        transform: translateY(200px);
    }

    :hover svg line.right {
        transform: translateY(-200px);
    }

`;

class Contact extends Component {

    static defaultProps = {
    };

    static states = {
    }

    componentDidMount() {

    }

    render() {
        const data = this.props.data;
        return (

            <StyledThumb image={data.image} onClick={() => { this.props.onPress(data) }
            }>
                <div className="hover"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                    <line className="top" x1="0" y1="0" x2="900" y2="0" />
                    <line className="left" x1="0" y1="200" x2="0" y2="-920" />
                    <line className="bottom" x1="300" y1="200" x2="-600" y2="200" />
                    <line className="right" x1="300" y1="0" x2="300" y2="600" />
                </svg>
                <StyledText>
                    <div className="title"><span className="first">{data.name.charAt(0)}</span>{data.name.substring(1, data.name.length)}</div>
                    <div className="body">{data.location} <p /> {data.website}</div>
                </StyledText>

            </StyledThumb >
        );
    }
}

export default Contact;
