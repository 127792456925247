import React, { Component } from "react";
import styled from "styled-components";

import { Container } from "semantic-ui-react";

import { TweenMax, TimelineMax } from "gsap/all";

import Thumb from "components/portofolio/thumb";
import Details from "components/portofolio/details";

import data from "data/portfolio.json";

const StyledServices = styled.section`
`;

const StyledDesc = styled.div`
    margin: 0 auto;
    width: 75%;
    padding: 20px;
    text-align: center;
`

const StyledPortofolio = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledBarMenu = styled.ul`
    position: relative;
    border-radius: 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    display: flex;
    float: left;
    margin: 30px 0;
    padding: 0;
    
    li{
        &.selected{
            background: #2881B2;
            border-radius: 20px;
            position: absolute;
            left: 0px;
            width: 61px;
            height: 35px;
            z-index: -1;
            
        }

        a{
            display:flex;
            padding: 8px 20px;
            animation-duration: 7s;
            text-transform: capitalize;
            &.active{
                color: #ffffff;
                animation-duration: 7s;
            }
           
        }
    }
`;

const StyledPortfolioList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    justify-content: center;
    margin-bottom: 60px;
    li{
        display: block;
        margin-left: 20px;
        margin-bottom: 20px;

    }
`;

class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.tl = new TimelineMax();
        this.selected = null;
        this.onClose = this.onClose.bind(this);
        this.onPress = this.onPress.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    static defaultProps = {
    };

    state = {
        pressed: "all"
    }

    componentDidMount() {
    }

    onFilter(event) {
        event.preventDefault();

        const target = event.target;

        TweenMax.to(this.selected, 0.5, { left: target.offsetLeft, width: target.clientWidth });
        this.setState({
            pressed: target.attributes["name"].value
        });
    }

    onPress(data) {
        this.setState({
            details: data
        });
    }

    onClose(event) {
        this.setState({
            details: null
        });
    }

    render() {

        return (
            <StyledServices id="portfolio">
                <Container>
                    <h2>
                        Our <span className="blue">Portfolio</span>
                    </h2>
                    <StyledDesc>We are a passionate creative design agency that specializes in beautiful and easy-to-use creative design & web development services.</StyledDesc>
                    <StyledPortofolio>
                        <StyledBarMenu>
                            <li className="selected" ref={li => this.selected = li}></li>
                            {
                                Object.keys(data.portfolio).map((el, i) => {
                                    return <li key={i}><a href={"#" + el} className={this.state.pressed === el ? "active" : ""} onClick={this.onFilter} name={el}>{el.replace("_", " ")}</a></li>

                                })
                            }
                        </StyledBarMenu>
                    </StyledPortofolio>
                    <StyledPortfolioList>
                        {
                            Object.keys(data.portfolio).map((k) => {
                                if (k === this.state.pressed || this.state.pressed === 'all') {
                                    return (data.portfolio[k].map((el, i) => {
                                        return <li key={i}><Thumb data={el} onPress={this.onPress} /></li>
                                    }))
                                }
                                return null;
                            })

                        }
                    </StyledPortfolioList>
                    {this.state.details && <Details data={this.state.details} onClose={this.onClose} />}

                </Container>
            </StyledServices>
        );
    }
}

export default Portfolio;
