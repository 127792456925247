import React, { Component } from 'react';
import styled from 'styled-components';

import logoImage from "assets/images/logo.svg";

const StyledLogo = styled.a`
    font-size: 28px;
    font-family: "Inconsolata", serif;
    color: #14455D;
    background: transparent url(${logoImage})  no-repeat center center;
    background-size: contain;
    display: block; 
    height: 60px;
    width: 55px;
    display: flex;
    align-items: center;
    text-decoration: none;
    :after {
        content: "bluetrendy";
        padding-left: 70px;
    }
`;

class Logo extends Component {
    render() {
        return (
            <StyledLogo className="logo" href="#home"></StyledLogo>
        );
    }
}

export default Logo;
