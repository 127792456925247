import React, { Component } from "react";

import Header from "components/header/header";
import Homepage from "pages/homepage";
import Services from "pages/services";
import Portfolio from "pages/portfolio";
import Clients from "pages/clients";
import Contact from "pages/contact";


class Index extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <Homepage />
                <Services />
                <Portfolio />
                <Clients />
                <Contact />
            </React.Fragment>
        )
    }
}

export default Index;
