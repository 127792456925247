import React, { Component } from 'react';
import styled from 'styled-components';

import { TweenMax, } from "gsap/all";
import { colors, sizes } from "theme";

const StyledMenuOverlay = styled.div`
    @media (max-width: ${sizes.sm}) {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(255,255,255,0);
         
        &.hide{
            display:none;
        }
    }
`;

const StyledMenu = styled.ul`
    display: flex;
    font-size: 14px;
    list-style-type: none;
    li{
        margin-right:20px;
        a {
            text-decoration: none;
            color: black;
            padding: 5px;
            border: 1px solid transparent;
            :hover,
            :active{
                color: #14455D;
                border: 1px solid #14455D;
            }

      
        }
        :last-child{
            margin-right: 0px;
        }
     }

    @media (max-width: ${sizes.sm}) {
        position: fixed;
        flex-direction: column;
        background: ${colors.blue["100"]};
        top: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 3;
        margin: 0px;
        padding: 0px;

        transition: left 0.3s ease;

        li{
            margin:0px;
            border-bottom: 1px solid ${colors.grey[200]};
       
            :last-child{
                 border-bottom: 1px solid transparent;
            }
            a{
                padding: 14px 100px 14px 20px;
                color: #ffffff;
                float:left;
                width: 100%;
                :hover,
                :active{
                    color: #ffffff;;
                    border: 1px solid transparent;
                    background: ${colors.blue["50"]};
                }
            }
        }
        &.hide{
            left: -200px;
        }

       
      
    }
`;


const StyledBurgerMenu = styled.div`
    display: none;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 3;
    cursor: pointer;

    div{
        width: 24px;
        height: 2px;
        background-color: ${colors.blue["100"]};
        margin: 4px 0;
        transition: all 0.4s;

        &.open{
            :first-child{
                transform: rotate(-45deg) translate(-4px, 4px);
            }
            :nth-child(2){
               opacity: 0;
            }
            :last-child{
                transform: rotate(45deg) translate(-4px, -4px);
            }
        }
    }

    :hover{
        div{
            background-color: ${colors.blue["50"]};
        }
    }

     @media (max-width: ${sizes.sm}) {
         display:inline-block;
     }

`;

const sections = [{
    name: "Services",
    id: "services"
}, {
    name: "Portfolio",
    id: "portfolio"
}, {
    name: "Clients",
    id: "clients"
}, {
    name: "Contact",
    id: "contact"
}]

class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.onToggleMenu = this.onToggleMenu.bind(this);
    }

    state = { visible: false }

    scrollTo(event) {
        const id = event.target.getAttribute('href');

        event.preventDefault();
        event.stopPropagation();

        TweenMax.to(window, 1, { scrollTo: id, onComplete: () => window.location.href = id });
    }

    onToggleMenu(event) {
        this.setState({ visible: !this.state.visible });

    }

    render() {
        const bars = [0, 1, 2];
        return (
            <React.Fragment>
                <StyledMenuOverlay onClick={this.onToggleMenu} className={this.state.visible ? "show" : "hide"} />
                <StyledBurgerMenu onClick={this.onToggleMenu}>
                    {
                        bars.map((i) => <div className={this.state.visible ? "open" : ""} key={i}></div>)
                    }
                </StyledBurgerMenu>

                <StyledMenu className={this.state.visible ? "show" : "hide"}>
                    {
                        sections.map((el, i) => {
                            return (<li key={i}><a href={'#' + el.id} onClick={this.scrollTo}>{el.name}</a></li>);
                        })
                    }
                </StyledMenu>


            </React.Fragment>
        );
    }
}

export default MainMenu;
