import React, { Component } from "react";
import styled from "styled-components";
import axios from 'axios';

// import CurvedLine from "components/line/curved";
import { Dropdown } from 'semantic-ui-react';
import { sizes, colors } from "theme";

import {
    Icon,
    Grid,
    Container
} from "semantic-ui-react";

const StyledContact = styled.section`
    i.bluetrendy{
        color: ${colors.blue["50"]};
    }
`;

const StyledForm = styled.form`
    margin-top: 40px;
`;


const StyledText = styled.div`
    margin: 0 auto;
    width: 75%;
    padding: 20px;
    text-align: center;
    @media (max-width: ${sizes.xs}) {
        width: 100%;
        padding: 20px 0px;
        text-align:left;
    }
`;

const StyledCopyRight = styled.div`
`;

const StyledButton = styled.button`
    padding: 5px 10px;
    border: 1px solid ${colors.blue["50"]};
    font-family: "Inconsolata",sans-serif !important;
    font-size: 14px;
    color: ${colors.blue["50"]};
    background:#ffffff;
    text-transform: uppercase;
    &.disabled{
        pointer-events: none;
        opacity: .5;
    }
    :hover{
        color: #ffffff;
        background-color: ${colors.blue["50"]};
        cursor: pointer;
    }
`;

const StyledInput = styled.input`
    font-family: "Inconsolata", monospace !important;
    width: 100%;
    padding: 10px;
    border: 1px solid #eeeeee;
`;

const StyledTextarea = styled.textarea`
    font-family: "Inconsolata", monospace !important;
    width: 100%;
    padding: 10px;
    border: 1px solid #eeeeee;
`;

const StyledSubject = styled(Dropdown)`
    font-family: "Inconsolata", monospace !important; 
    width: 100%;
    &.ui.selection.dropdown{
        border-radius: 0px;
    }
`;

const StyledMessage = styled.div`
`;

class Contact extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.messageStatus = this.messageStatus.bind(this);
    }

    static defaultProps = {
    };

    state = {
        sending: false,
        messageStatus: "not_send"
    };

    componentDidMount() {

    }

    onSelect(event, { name, value }) {
        this.setState({
            [name]: value
        });

    }
    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    sendMessage(event) {
        event.preventDefault();

        this.setState({
            sending: true
        });

        const data = {
            secret: "X9gV1d8zWsMM",
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message
        }

        axios.post('/api/mail.php', data)
            .then((response) => {
                this.messageStatus(response, false);
            })
            .catch((error) => {
                this.messageStatus(error, true);
            });
    }

    messageStatus(data, error) {
        this.setState({
            sending: false
        });

        if (error) {
            this.setState({
                messageStatus: "error",
                message: "There is an error sending the message."
            });
        } else {
            this.setState({
                messageStatus: "success",
                message: "Message was send succesfully."
            });
        }
    }

    render() {
        const services = [
            { key: 'web_development', value: 'Web Development', text: 'Web Development' },
            { key: 'ecommerce', value: 'Ecommerce', text: 'Ecommerce' },
            { key: 'software_development', value: 'Software Development', text: 'Software Development' },
            { key: 'server_development', value: 'Server Development', text: 'Server Development' },
            { key: 'app_development', value: 'App Development', text: 'App Development' },
            { key: 'game_development', value: 'Game Development', text: 'Game Development' },
            { key: 'cloud_services', value: 'Cloud Services', text: 'Cloud Services' },
            { key: 'ux_ui_design', value: 'UX/UI Design', text: 'UX/UI Design' },
            { key: 'other', value: 'Other', text: 'Other' }
        ];

        return (

            < StyledContact id="contact" >
                <Container>
                    <h2>
                        Get in <span className="blue">Touch</span>
                    </h2>

                    <StyledText>We are a passionate in-house software development agency that specializes in beautiful and easy-to-use digital design & development services.</StyledText>

                    <Grid>
                        <Grid.Column mobile={16} computer={8}>
                            <Grid>
                                <Grid.Column computer={16}>
                                    <h3>Contact Us</h3>
                                </Grid.Column>
                                <Grid.Column computer={16}>
                                    <div>We are available 24/7 by fax, e-mail or by phone. You can also use our quick contact form to ask a question about our services and projects.</div>
                                </Grid.Column>
                                <Grid.Column computer={16}>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <h4>London, United Kingdom</h4>
                                        </Grid.Column>
                                        <Grid.Column computer={2}>
                                            <Icon name="phone" className="bluetrendy " />
                                        </Grid.Column>
                                        <Grid.Column computer={14}>
                                            <b>Phone:</b> +44 (0) 7815.627.527
                                        </Grid.Column>
                                        <Grid.Column computer={2}>
                                            <Icon name="envelope outline" className="bluetrendy large" />
                                        </Grid.Column>
                                        <Grid.Column computer={14}>
                                            <b>Email:</b> <a href="mailto:contact@bluetrendy.com">contact@bluetrendy.com</a>
                                        </Grid.Column>
                                        <Grid.Column computer={2}>
                                            <Icon name="map marker alternate" className="bluetrendy large" />
                                        </Grid.Column>
                                        <Grid.Column computer={14}>
                                            <b>Address:</b><br />Fulham road<br />SW6 5HQ<br />London, UK
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>

                        </Grid.Column>
                        <Grid.Column mobile={16} computer={8}>

                            {
                                this.state.messageStatus === "not_send" &&
                                <StyledForm onSubmit={this.sendMessage}>
                                    <Grid>
                                        <Grid.Column computer={8}>
                                            <StyledInput onChange={this.onChange} name="firstname" placeholder="First Name..." required />
                                        </Grid.Column>
                                        <Grid.Column computer={8}>
                                            <StyledInput onChange={this.onChange} name="lastname" placeholder="Last Name..." required />
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <StyledInput onChange={this.onChange} name="email" placeholder="Email..." type="email" required />
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <StyledInput onChange={this.onChange} name="phone" placeholder="Phone..." required />
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <StyledSubject onChange={this.onSelect} name="subject" placeholder='Subject...' selection options={services} required />
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <StyledTextarea onChange={this.onChange} name="message" rows="6" placeholder="Message..." required />
                                        </Grid.Column>
                                        <Grid.Column computer={16} textAlign="right">
                                            <StyledButton className={this.state.sending ? "disabled" : ""} >
                                                Send Message
                                                </StyledButton>
                                        </Grid.Column>
                                    </Grid>
                                </StyledForm>
                            }
                            {
                                this.state.messageStatus === "error" &&
                                <StyledMessage>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <h3>Message Error</h3>
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <div>{this.state.message}</div>
                                        </Grid.Column>
                                    </Grid>
                                </StyledMessage>
                            }

                            {
                                this.state.messageStatus === "success" &&
                                <StyledMessage>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <h3>Message Send</h3>
                                        </Grid.Column>
                                        <Grid.Column computer={16}>
                                            <div>{this.state.message}</div>
                                        </Grid.Column>
                                    </Grid>
                                </StyledMessage>
                            }
                        </Grid.Column>
                    </Grid>
                </Container>
                <StyledCopyRight>
                    <Container>
                        <Grid>
                            <Grid.Column computer={16} textAlign="center">
                                © 2019 BlueTrendy Limited. All Rights Reserved.
                        </Grid.Column>
                        </Grid>
                    </Container>
                </StyledCopyRight>
            </StyledContact >
        );
    }
}

export default Contact;
