import React, { Component } from "react";
import styled from "styled-components";

import { Grid } from "semantic-ui-react";
import { Container } from "semantic-ui-react";

import { TweenMax, SplitText } from "gsap/all";

import { sizes, colors } from "theme";

import CurvedLine from 'components/line/curved';
import banner from "assets/images/lotus.jpg";

const StyledHomepage = styled.section`
`;


const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 180px;
    margin-top: -100px;
   
    height: 100%;

    @media (max-width: ${sizes.sm}) {
        margin: 0px;
        margin-bottom: 120px;
    }
`;


const StyledText = styled.div`
    position: relative;
    font-size: 32px;
    font-family: "Quicksand", sans-serif;
    color: #14455D;
    line-height: normal;
     :before{
        position: absolute;
        font-size: 166px;
        color: ${colors.blue["50"]};
        content: '”';
        left:-60px;
        top:-110%;
    }

     @media (max-width: ${sizes.sm}) {
         align-self: center;
        :before{
            left: 0px;
            top: -110%;
        }
    }
  
`;

const StyledServiceWrapper = styled.div`
    position: relative;
    width: 100%;
    @media (max-width: ${sizes.sm}) { 
        display: flex;
        justify-content: center;
    }
`;

const StyledService = styled.div`
    position: absolute;
    font-size: 52px;
    margin-top: 20px;
    color: ${colors.blue["50"]};

     @media (max-width: ${sizes.sm}) {
        font-size: 42px;
     }
`;


const StyledBanner = styled.div`
    background: transparent url(${banner})  no-repeat top right;
    height: 500px;
    background-size: contain;
    margin-top: 100px;
    margin-right: 40px;


    @media (max-width: ${sizes.sm}) {
        margin: 40px;
        background-position: center center;
    }
`;

const services = [
    "web software",
    "api servers",
    "databases",
    "mobile apps",
    "web development",
    "game development"
];

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.text = null;
        this.services = [];
        this.animateServices = this.animateServices.bind(this);
    }

    componentDidMount() {
        for (let i = 0; i < services.length; i++) {
            let text = new SplitText(this.services[i], { type: "chars" });
            TweenMax.set(text.chars, { opacity: 0, rotationY: 90 });

            services[i] = text;
        }

        this.animateServices(0);

    }

    animateServices(id) {
        TweenMax.staggerTo(services[id].chars, 0.8, { y: 10, rotationY: 0, opacity: 1, delay: 1 }, 0.02);
        TweenMax.staggerTo(services[id].chars, 0.3, { y: 0, rotationY: 90, opacity: 0, delay: 4 }, 0.01);

        id = (id === services.length - 1) ? 0 : ++id;

        TweenMax.delayedCall(3, this.animateServices, [id]);
    }

    render() {
        return (
            <StyledHomepage >
                <Container>
                    <Grid reversed="mobile vertically tablet vertically">
                        <Grid.Column mobile={16} computer={10}>
                            <StyledTextWrapper>
                                <StyledText ref={div => this.text = div}>
                                    A team of pioneers<br />specialized in
                                </StyledText>
                                <StyledServiceWrapper>
                                    {
                                        services.map((el, i) => {
                                            return <StyledService ref={div => this.services[i] = div} key={i}>{el}</StyledService>
                                        })
                                    }
                                </StyledServiceWrapper>
                            </StyledTextWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} computer={6}>
                            <StyledBanner />
                        </Grid.Column>
                    </Grid>

                </Container>
                <CurvedLine color="#ffffff" type="white" />
            </StyledHomepage>

        );
    }
}

export default Homepage;
