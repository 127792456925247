

import React, { Component } from "react";
import styled from "styled-components";

const StyledCurved = styled.div`
    position: relative;
    top: ${props => props.type === "white" ? "0px" : "-50px"}  ;
    width: 100%;

    :before{
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        content: '';
        border-right: ${props => props.type === "white" ? "30vw" : "70vw"} solid transparent;
        border-top: ${props => props.type === "white" ? "50px solid #ffffff" : "0"};
        border-bottom: ${props => props.type === "white" ? "0" : "50px solid #ffffff"};

        
    }

    :after{
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        content: '';
        border-left: ${props => props.type === "white" ? "70vw" : "30vw"} solid transparent;
        border-top: ${props => props.type === "white" ? "50px solid #ffffff" : "0"};
        border-bottom: ${props => props.type === "white" ? "0" : "50px solid #ffffff"};
    }
`

class Curved extends Component {
    static defaultProps = {
    };

    static states = {
    }

    componentDidMount() {

    }


    render() {
        return (
            <StyledCurved type={this.props.type} />
        );
    }
}

export default Curved;
