import React, { Component } from "react";
import styled from "styled-components";

import CurvedLine from "components/line/curved";
import { Container } from "semantic-ui-react";

import { sizes } from "theme";

import exxon from "assets/images/clients/exxon.svg";
import creditsuisse from "assets/images/clients/creditsuisse.svg";
import ee from "assets/images/clients/ee.svg";
import hmrc from "assets/images/clients/hmrc.svg";
import samsung from "assets/images/clients/samsung.svg";
import sky from "assets/images/clients/sky.svg";
import lloydsBank from "assets/images/clients/lloydsbank.svg";
import hsbc from "assets/images/clients/hsbc.svg";

const StyledClients = styled.section`
    background: #eeeeee;
    padding: 20px;
`;

const StyledList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    padding: 0;
    li{
        border-bottom: 1px dashed #dddddd;
        border-right: 1px dashed #dddddd;
        padding:40px;

        :nth-child(4n+0){
            border-right: none;
        }

        :nth-child(n+5){
            border-bottom: none;
        }

        div{
            width: 160px;
            height: 120px;
           
            opacity: 0.3;
            &.exxon{
                background: url(${exxon}) no-repeat center center;
                background-size:contain;
            }
            &.creditsuisse{
                background: url(${creditsuisse}) no-repeat center center;
                background-size:contain;
            }
            &.ee{
                background: url(${ee}) no-repeat center center;
                background-size:contain;
            }
            &.hmrc{
                background: url(${hmrc}) no-repeat center center;
                background-size:contain;
            }
            &.samsung{
                background: url(${samsung}) no-repeat center center;
                background-size:contain;
            }
            &.sky{
                background: url(${sky})  no-repeat  center center;
                background-size:contain;
            }
            &.lloydsBank{
                background: url(${lloydsBank}) no-repeat center center;
                background-size:contain;
            }
            &.hsbc{
                background: url(${hsbc}) no-repeat center center;
                background-size:contain;
            }
            
        }

        @media (max-width: ${sizes.md}) {
            border-right: none;
            border-bottom: 1px dashed #dddddd;
            :nth-child(n+5){
               border-bottom: 1px dashed #dddddd;
            }
        }

        @media (max-width: ${sizes.sm}) {
            border-right: none;
            border-bottom: 1px dashed #dddddd;

            :nth-child(n+5){
               border-bottom: 1px dashed #dddddd;
            }
        }

        @media (max-width: ${sizes.xs}) {
            :last-child{
                border-bottom: none;
            }
        }
    }
`;



const clients = [{
    name: "exxon",
    class: "exxon",
    logo: "exxon.svg"
}, {
    name: "Credit Suisse",
    class: "creditsuisse",
    logo: "creditSuisse.svg"
}, {
    name: "EE",
    class: "ee",
    logo: "ee.svg"
}, {
    name: "Hmrc",
    class: "hmrc",
    logo: "hmrc.svg"
}, {
    name: "Samsung",
    class: "samsung",
    logo: "samsung.svg"
}, {
    name: "Sky TV",
    class: "sky",
    logo: "sky.svg"
}, {
    name: "Lloyds Bank",
    class: "lloydsBank",
    logo: "lloydsBank.svg"
}, {
    name: "HSBC Bank",
    class: "hsbc",
    logo: "hsbc.svg"
}];

class Clients extends Component {

    static defaultProps = {
    };


    componentDidMount() {

    }


    render() {
        return (
            <React.Fragment >
                <CurvedLine type="white" />
                <StyledClients id="clients">
                    <Container>
                        <h2>
                            Our <span className="blue">Clients</span>
                        </h2>

                        <StyledList>
                            {clients.map((el, i) => {
                                return <li key={i}>
                                    <div className={el.class} ></div>
                                </li>
                            })}

                        </StyledList>

                    </Container>
                </StyledClients>
                <CurvedLine type="grey" />
            </React.Fragment >
        );
    }
}

export default Clients;
