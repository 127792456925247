export const colors = {
    "blue": {
        "50": "#2881B2",
        "100": "#14455D",
        "200": "#0f394e"
    },
    "grey": {
        "50": "#eeeeee",
        "100": "#dddddd",
        "200": "#424242",
        "300": "#333333"
    }
}


export const sizes = {
    "xs": "768px",
    "sm": "992px",
    "md": "1200px"
}
