import React, { Component } from "react";
import styled from "styled-components";

import { TimelineMax } from "gsap/all";

import { Icon } from "semantic-ui-react";

const StyledCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 84px;
    height: 84px;
    background: #2881B2;
    border-radius:100%;

    i.icon{
        margin: 0px;
    }

    :hover{
        cursor: pointer;
        background-color: #14455D;
    }
`;

const StyledTitle = styled.div`
    position: absolute;
    top: 90px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
`;


class Circle extends Component {
    constructor(props) {
        super(props);
        this.element = null;
        this.tl = new TimelineMax();
        this.choose = this.choose.bind(this);
    }

    choose() {
        this.props.clickEvent(this.props.id);
    }


    componentDidMount() {
        var angle = (this.props.length - this.props.id / ((this.props.length - 1) / 2)) * Math.PI / 2;
        var radius = 300;
        var x = radius * Math.cos(angle);
        var y = radius * Math.sin(angle);

        this.tl
            .from(this.element, 2, { alpha: 0 }, "enter")
            .to(this.element, 2, { x: x, y: y }, "enter");
    }

    render() {
        return (
            <StyledCircle ref={div => this.element = div} onClick={this.choose}>
                <Icon inverted size="big" name={this.props.data.icon} />
                <StyledTitle dangerouslySetInnerHTML={{ __html: this.props.data.name }} />
            </StyledCircle >
        );
    }
}

export default Circle;
